import config from '/config';
import PropTypes from 'prop-types'
import * as Sentry from "@sentry/react";
import eventFilters from './sentry-event-filters'
import { useEffect } from 'react';

// import testEvent from '/data/test-sentry-event';

const processEvent = (event, hint) => {
	let newEvent = {...event};
	for(const eventFilter of (eventFilters ?? [])) {
		if(eventFilter && newEvent) {
			newEvent = eventFilter(newEvent, hint);
		}
	}
	return newEvent;
}

try {
	Sentry.init({
		environment: config.environment ?? 'production',
		maxBreadcrumbs: 50,
		dsn: config.sentry.dsn,
		integrations: [
			new Sentry.BrowserTracing(),
			new Sentry.Integrations.Breadcrumbs({ console: false }),
		],
		tracesSampleRate: config.sentry?.tracesSampleRate ?? 0.1,
		replaysSessionSampleRate: config.sentry?.replaysSessionSampleRate ?? 0.1,
		replaysOnErrorSampleRate: config.sentry?.replaysOnErrorSampleRate ?? 1.0,
		beforeSendTransaction: processEvent,
		beforeSend: processEvent,
		ignoreErrors: [
			"Non-Error promise rejection captured",
			"Object captured as promise rejection with keys:",
			"Can't find variable: gmo",
		],
		denyUrls: [
			/extensions\//i,
			/^ chrome: \/\//i,
			/maps\.googleapis\.com/i,
		],
	});
}
catch(e) {
	console.error('Sentry error:', e);
}

export const SentryProvider = ({ children, serverProps }) => {
	useEffect(() => {
		import("@sentry/browser").then(({ getCurrentHub, Replay }) => {
			getCurrentHub().getClient().addIntegration(new Replay({
				replaysOnErrorSampleRate: 0.1,
				maskAllText: false,
				maskAllInputs: true,
				blockAllMedia: false,
				unmask: ['.search-input', 'input[type="checkbox"]', 'input[type="radio"]']
			}));
		})
	}, [])

	if(serverProps?.isServer === true || serverProps?.isServer === false ) {
		Sentry.setTag('isApplication', !!serverProps?.isApplication);
		Sentry.setTag('isMobile', !!serverProps?.mediaStatus?.mobile);
	}

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		const testResult = processEvent(testEvent);
	// 		console.log(testResult);
	// 	}, 2000)
	// }, [])

	return children;
}

SentryProvider.propTypes = {
	children: PropTypes.node,
}

export default SentryProvider;
